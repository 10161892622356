import React from "react"
import styled from "styled-components"

import Layout from "../components/layout/layout"
import Container from "../components/layout/container"
import SEO from "../components/seo/seo"

const Policy = styled.div`
  margin: auto;

  ol {
    list-style: lower-latin;
  }
`

const H1 = styled.h1`
  font-size: 60px;
`

const privacy = () => {
  return (
    <Layout>
      <SEO
        title="Cookie Policy – CEO amp"
        description="All Cookies used by and on our website are used in accordance with
            current English and EU Cookie Law."
      />
      <Container>
        <Policy>
          <H1>Cookie Policy</H1>
          <p>
            This policy covers&nbsp;
            <a href="https://www.ceoamp.com" target="_blank" rel="nofollow">
              https://www.ceoamp.com
            </a>
            &nbsp;and its associated subdomains, and is operated by Raconteur
            Media&nbsp;Ltd.
          </p>
          <p>
            All Cookies used by and on our website are used in accordance with
            current English and EU Cookie&nbsp;Law.
          </p>
          <p>
            The site uses cookies or similar technology to collect information
            about your access to the site. Cookies are pieces of information
            that include a unique reference code that a website transfers to
            your device to store and sometimes track information about&nbsp;you.
          </p>
          <p>
            A few of the cookies we use last only for the duration of your web
            session and expire when you close your browser. Other cookies are
            used to remember you when you return to the site and will last
            for&nbsp;longer.
          </p>
          <p>
            <strong>All cookies used on our site are set by&nbsp;us.</strong>
          </p>
          <p>
            Most computer and some mobile web browsers automatically accept
            cookies but, if you prefer, you can change your browser to prevent
            that or to notify you each time a cookie is set. You can prevent the
            setting of cookies by adjusting the settings on your browser. Please
            note however, that by blocking or deleting cookies you may not be
            able to take full advantage of the&nbsp;site.
          </p>
          <h3>Our cookies will be used&nbsp;for:</h3>
          <p>
            <strong>Essential session management</strong>
          </p>
          <p>
            • creating a specific log-in session for a user of the site in order
            that the site remembers that a user is logged in and that their page
            requests are delivered in an effective, secure and consistent
            manner;
          </p>
          <p>
            • recognising when a user of the site has visited before allowing us
            to identify the number of unique users we receive to the site and
            make sure we have enough capacity for the number of users that
            we&nbsp;get;
          </p>
          <p>
            • recognising if a visitor to the site is registered with us in
            any&nbsp;way;
          </p>
          <p>
            • we may also log information from your computer including the
            existence of cookies, your IP address and information about your
            browser program in order to allow us to diagnose problems,
            administer and track your usage of our&nbsp;site.
          </p>
          <p>
            <strong>Functionality</strong>
          </p>
          <p>
            • customising elements of the promotional layout and/or content of
            the pages of the&nbsp;site.
          </p>
          <p>
            <strong>Performance and measurement</strong>
          </p>
          <p>
            • collecting statistical information about how our users use the
            site so that we can improve the site and learn which parts are most
            popular to&nbsp;users.
          </p>
          <h3>How do third parties use&nbsp;cookies?</h3>
          <p>
            Third party companies like analytics companies and ad networks
            generally use cookies to collect user information on an anonymous
            basis. They may use that information to build a profile of your
            activities on the Raconteur Media Ltd Website and other websites
            that you’ve&nbsp;visited.
          </p>
          <h3>What are your cookies&nbsp;options?</h3>
          <p>
            If you don’t like the idea of cookies or certain types of cookies,
            you can change your browser’s settings to delete cookies that have
            already been set and to not accept new cookies. To learn more about
            how to do this, visit the help pages of your browser&nbsp;below.
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666"
                target="_blank"
                rel="nofollow"
              >
                Cookie settings in Google&nbsp;Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10"
                target="_blank"
                rel="nofollow"
              >
                Cookie settings in Internet&nbsp;Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                target="_blank"
                rel="nofollow"
              >
                Cookie settings in&nbsp;Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/kb/PH17191?locale=en_US"
                target="_blank"
                rel="nofollow"
              >
                Cookie settings in Safari
              </a>{" "}
              and{" "}
              <a
                href="https://support.apple.com/en-gb/HT201265"
                target="_blank"
                rel="nofollow"
              >
                iOS
              </a>
            </li>
          </ul>
          <p>
            It is also possible to turn off advertising cookies on a
            cookie-by-cookie basis. You can find information about how to opt
            out of the cookies used by both Raconteur and our key
            partners&nbsp;here:
          </p>
          <p>Google Analytics (Performance cookie):</p>
          <ul>
            <li>
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
                rel="nofollow"
              >
                Opt out
              </a>
            </li>
            <li>
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow"
              >
                Privacy policy
              </a>
            </li>
          </ul>
          {/* <p>Facebook (Advertising cookie):</p>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/help/568137493302217"
                target="_blank"
                rel="nofollow"
              >
                Opt out
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/about/privacy/update?ref=old_policy"
                target="_blank"
                rel="nofollow"
              >
                Privacy policy
              </a>
            </li>
          </ul>
          <p>LinkedIn (Advertising cookie):</p>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out?trk="
                target="_blank"
                rel="nofollow"
              >
                Opt out
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/legal/privacy-policy?trk="
                target="_blank"
                rel="nofollow"
              >
                Privacy policy
              </a>
            </li>
          </ul> */}
          <p>
            Please note, however, that if you delete cookies or do not accept
            them, you might not be able to use all of the features we offer, you
            may not be able to store your preferences, and some of our pages
            might not display&nbsp;properly.
          </p>
          <h3>More info</h3>
          <p>
            You can learn more about cookies by visiting{" "}
            <a
              href="http://cookies.insites.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              http://cookies.insites.com
            </a>
          </p>
        </Policy>
      </Container>
    </Layout>
  )
}

export default privacy
